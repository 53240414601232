import React, { useContext } from 'react'
import { ContextProvider } from '../context/index';
import Loading from '../Components/Loading';

const PrivateRoute = ({ children }) => {

    const { user } = useContext(ContextProvider)

    // If the user is authenticated, show children
    // If the user is not authenticated, send them to homepage
    // Otherwise, show loading
    return user.isUserAuthenticated === true ?
        children
        : user.isUserAuthenticated === false ?
            window.location.href = "/"
            : <Loading />
}

export default PrivateRoute