const controllers = {
    create: require("./controllers/create"),
    users: require("./controllers/users"),
    admins: require("./controllers/admins")
};

// *********************************** Admins *******************************************
export const getCollectionPuzzles = async (collection, callback) => {
    return controllers.admins.getCollectionPuzzles(collection, callback)
}

export const getPuzzleForAdmin = async (puzzleId, puzzleAuthor, callback) => {
    return controllers.admins.getPuzzleForAdmin(puzzleId, puzzleAuthor, callback)
}

export const editPuzzleForAdmin = async (puzzleData, callback) => {
    return controllers.admins.editPuzzleForAdmin(puzzleData, callback)
}

export const deletePuzzleForAdmin = async (puzzleId, puzzleAuthor, callback) => {
    return controllers.admins.deletePuzzleForAdmin(puzzleId, puzzleAuthor, callback)
}

export const approvePuzzle = async (puzzleId, puzzleAuthor, callback) => {
    return controllers.admins.approvePuzzle(puzzleId, puzzleAuthor, callback)
}

export const rejectPuzzle = async (puzzleId, puzzleAuthor, callback) => {
    return controllers.admins.rejectPuzzle(puzzleId, puzzleAuthor, callback)
}

// ******************************** Create *******************************************
export const createPuzzle = async (puzzleData, callback) => {
    return controllers.create.createPuzzle(puzzleData, callback)
}

export const createDraftPuzzle = async (puzzleData, callback) => {
    return controllers.create.createDraftPuzzle(puzzleData, callback)
}

export const editPuzzle = async (puzzleData, callback) => {
    return controllers.create.editPuzzle(puzzleData, callback)
}

export const deletePuzzle = async (puzzleId, callback) => {
    return controllers.create.deletePuzzle(puzzleId, callback)
}


// *********************************** User *******************************************
export const getUser = async (callback) => {
    return controllers.users.getUser(callback)
}

export const getPuzzle = async (puzzleId, callback) => {
    return controllers.users.getPuzzle(puzzleId, callback)
}

export const getPuzzles = async (callback) => {
    return controllers.users.getPuzzles(callback)
}

