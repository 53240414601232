import { getAuth, onAuthStateChanged } from "firebase/auth";
const { API_ROOT_URL } = require("../../../configs/activebuildconfig.json")

/**
 * CONTROLLER - admins
 * FUNCTION - rejectPuzzle
 * VERIFY - admin
 * @param {*} puzzleId The Id of the puzzle we want to get
 * @param {*} puzzleAuthor The user who created the puzzle
 * @param {*} callback Callback function
 * @returns Nothing
 */

export const rejectPuzzle = async (puzzleId, puzzleAuthor, callback) => {
    const auth = getAuth();
    onAuthStateChanged(auth, async (user) => {
        const token = await user?.getIdToken(false);
        const response = await fetch(`${API_ROOT_URL}/admins/reject_puzzle`, {
            method: "PUT",
            headers: {
                Accept: "application/json",
                "Content-type": "application/json",
            },
            body: JSON.stringify({
                token,
                puzzleId,
                puzzleAuthor
            }),
        })
        const result = await response.json();
        callback(result)
    })
}