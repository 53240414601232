import { getAuth, onAuthStateChanged } from "firebase/auth";
const { API_ROOT_URL } = require("../../../configs/activebuildconfig.json")

/**
 * CONTROLLER - puzzle
 * FUNCTION - deletePuzzle
 * VERIFY - verified user
 * @param {*} puzzleId The data for the puzzle being deleted
 * @param {*} callback callback function
 * @returns Nothing
 */

export const deletePuzzle = async (puzzleId, callback) => {
    const auth = getAuth();
    onAuthStateChanged(auth, async (user) => {
        const token = await user?.getIdToken(false);
        const response = await fetch(`${API_ROOT_URL}/create/delete_puzzle`, {
            method: "PUT",
            headers: {
                Accept: "application/json",
                "Content-type": "application/json",
            },
            body: JSON.stringify({
                token,
                puzzleId
            }),
        })
        const result = await response.json();
        callback(result)
    })
}