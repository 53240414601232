import React, { useState, useContext, useEffect } from 'react';
import { editPuzzleForAdmin } from '../Api/api';
import { getPuzzleForAdmin } from '../Api/api';
import { isMobile } from 'react-device-detect';
import { useLocation, useNavigate } from "react-router-dom"
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import Clue from './Clue';
import Navbar from './Navbar'
import Footer from './Footer';
import Loading from './Loading';
import { ContextProvider } from '../context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTriangleExclamation, faAsterisk, faCheckCircle, faXmarkCircle } from "@fortawesome/pro-regular-svg-icons";
import "../Styles/createGame.css"

const ReviewPuzzle = () => {

    const location = useLocation();

    const navigate = useNavigate()

    const search = location.search;

    const puzzleId = location.pathname.split("/")[3]

    const isNewSubmition = new URLSearchParams(search).get('newSubmition');

    const puzzleAuthorEmail = new URLSearchParams(search).get('puzzleAuthorEmail');

    const { setStateOrLocalStorage, user } = useContext(ContextProvider)

    const [question, setQuestion] = useState(setStateOrLocalStorage("", "question"))

    const [riddleWord, setRiddleWord] = useState(setStateOrLocalStorage("", "riddleWord"))

    const [isBoxChecked, setIsBoxChecked] = useState(false)

    const [puzzleAuthor, setPuzzleAuthor] = useState(setStateOrLocalStorage("", "puzzleAuthor"))

    const [puzzleStatus, setPuzzleStatus] = useState("")

    const [dataCopy, setDataCopy] = useState(null)

    const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false)

    const [isPuzzleSuccessfullySubmitted, setIsPuzzleSuccessfullySubmitted] = useState(null);

    const [isPuzzleError, setIsPuzzleError] = useState(null)

    const [releaseDate, setReleaseDate] = useState("")

    const [isLoading, setIsLoading] = useState(true)

    const [clues, setClues] = useState(setStateOrLocalStorage(
        {
            "id-0": { clueText: "", clueDescription: "" },
            "id-1": { clueText: "", clueDescription: "" },
            "id-2": { clueText: "", clueDescription: "" },
            "id-3": { clueText: "", clueDescription: "" },
            "id-4": { clueText: "", clueDescription: "" },
            "id-5": { clueText: "", clueDescription: "" },
            "id-6": { clueText: "", clueDescription: "" },
            "id-7": { clueText: "", clueDescription: "" },
            "id-8": { clueText: "", clueDescription: "" },
            "id-9": { clueText: "", clueDescription: "" },
            "id-10": { clueText: "", clueDescription: "" },
            "id-11": { clueText: "", clueDescription: "" },
        }, "clues"))


    const [clueIndexes, setClueIndexes] = useState([
        "id-0",
        "id-1",
        "id-2",
        "id-3",
        "id-4",
        "id-5",
        "id-6",
        "id-7",
        "id-8",
        "id-9",
        "id-10",
        "id-11",
    ])

    const allowedRiddleWordCharacters = ["KeyA", "KeyB", "KeyC", "KeyD", "KeyE",
        "KeyF", "KeyG", "KeyH", "KeyI", "KeyJ", "KeyK", "KeyL", "KeyM", "KeyN", "KeyO", "KeyP", "KeyQ", "KeyR",
        "KeyS", "KeyT", "KeyU", "KeyV", "KeyW", "KeyX", "KeyY", "KeyZ", "Backspace", "ArrowLeft", "ArrowRight"]

    useEffect(() => {
        const unloadCallback = (event) => {
            event.preventDefault();
            event.returnValue = "";
            return "";
        };

        window.addEventListener("beforeunload", unloadCallback);
        return () => window.removeEventListener("beforeunload", unloadCallback);
    }, []);

    useEffect(() => {
        // Fetch the user's puzzle
        getPuzzleForAdmin(puzzleId, puzzleAuthorEmail, (result) => {
            if (result.success) {
                const { clues, puzzleAuthor, puzzleStatus, question, riddleWord } = result.data
                handleSetCluesFromBackend(clues)
                setPuzzleAuthor(puzzleAuthor)
                setQuestion(question)
                setRiddleWord(riddleWord)
                setPuzzleStatus(puzzleStatus)
                setDataCopy(result.data)
                if (puzzleStatus === "QUEUED") {
                    setReleaseDate(result.data.releaseDate)
                }
            }
            else {
                setIsPuzzleError(true)
            }
            setIsLoading(false)
        })
        window.scrollTo(0, 0)
    }, [])

    const handleSetCluesFromBackend = (clues) => {
        clues.forEach((clue, index) => {
            handleSetClueText(index, clue.clueText)
            handleSetClueDescription(index, clue.clueDescription)
        })
    }

    const setLocalStorage = (id, value) => {
        localStorage.setItem(id, JSON.stringify(value))
    }

    const handleSetClue = (clueIndex, newData, clueType) => {
        if (!isPuzzleEditable()) { return }
        if (isPuzzleSuccessfullySubmitted) {
            userEditedPuzzleAfterSubmission()
        }
        clueType === "TEXT" ? handleSetClueText(clueIndex, newData) :
            handleSetClueDescription(clueIndex, newData)
    }

    const handleSetClueText = (clueIndex, newClueText) => {
        clues[clueIndexes[clueIndex]].clueText = newClueText;
        setClues({ ...clues })
        setLocalStorage("clues", clues)
    }

    const handleSetClueDescription = (clueIndex, newClueDescription) => {
        clues[clueIndexes[clueIndex]].clueDescription = newClueDescription;
        setClues({ ...clues })
        setLocalStorage("clues", clues)
    }

    const areAllRequiredFieldsSubmitted = () => {
        let arecluesAndDescriptionsFilledIn = true
        for (const [, val] of Object.entries(clues)) {
            if (!val.clueText || !val.clueDescription) {
                arecluesAndDescriptionsFilledIn = false
            }
        }
        return arecluesAndDescriptionsFilledIn && question && riddleWord && isBoxChecked
    }

    const hasPuzzleBeenEdited = () => {
        const { puzzleAuthor: puzzleAuthorCopy, riddleWord: riddleWordCopy, question: questionCopy, clues: cluesCopy } = dataCopy;

        const areCluesTheSame = cluesCopy.every((elem, index) => {
            const { clueText: clueTextCopy, clueDescription: clueDescriptionCopy } = elem;
            const { clueText, clueDescription } = clues[`id-${index}`];

            return clueText === clueTextCopy && clueDescription === clueDescriptionCopy && clueIndexes[index] === `id-${index}`;
        });

        return !(puzzleAuthor === puzzleAuthorCopy && riddleWord === riddleWordCopy && question === questionCopy && areCluesTheSame)
    }

    // When the user edits a field after they have submitted their edits
    // Uncheck the checkbox
    // Set successful submission back to null
    // Set Edit button clicked to false
    const userEditedPuzzleAfterSubmission = () => {
        setIsBoxChecked(false)
        setIsPuzzleSuccessfullySubmitted(null)
        setIsSubmitButtonClicked(false)
    }

    const handleUpdatePuzzle = () => {
        if (!areAllRequiredFieldsSubmitted() || isSubmitButtonClicked || !hasPuzzleBeenEdited() || !isPuzzleEditable()) {
            return
        }
        setIsSubmitButtonClicked(true)
        const puzzleData = {
            question,
            riddleWord,
            puzzleAuthor,
            clues,
            puzzleId,
            clueIndexes,
            puzzleStatus,
            puzzleAuthorEmail
        }
        editPuzzleForAdmin(puzzleData, (result) => {
            if (result.success) {
                const { clues, puzzleAuthor, puzzleStatus, question, riddleWord } = result.data
                handleSetCluesFromBackend(clues)
                setPuzzleAuthor(puzzleAuthor)
                setQuestion(question)
                setRiddleWord(riddleWord)
                setIsPuzzleSuccessfullySubmitted(true)
                setPuzzleStatus(puzzleStatus)
                setDataCopy(result.data)
            }
            else {
                setIsPuzzleSuccessfullySubmitted(false)
            }
        })
    }

    const onDragEnd = result => {

        const { destination, source, draggableId } = result

        if (!destination) { return }

        if (destination.droppableId === source.droppableId &&
            destination.index === source.index) {
            return
        }

        const newClueIndexes = Array.from(clueIndexes)
        newClueIndexes.splice(source.index, 1)
        newClueIndexes.splice(destination.index, 0, draggableId)

        setClueIndexes([...newClueIndexes])

        if (isPuzzleSuccessfullySubmitted) {
            userEditedPuzzleAfterSubmission()
        }
    };

    const getSubmitButtonClassName = () => {
        if (isPuzzleSuccessfullySubmitted) {
            return "submit-puzzle-button success"
        } else if (isPuzzleSuccessfullySubmitted === false) {
            return "submit-puzzle-button failure"
        } else if (isSubmitButtonClicked) {
            return "submit-puzzle-button clicked"
        } else if (areAllRequiredFieldsSubmitted() && hasPuzzleBeenEdited()) {
            return 'submit-puzzle-button'
        } else {
            return 'submit-puzzle-button disabled'
        }
    }

    const getSubmitButtonText = () => {
        if (isPuzzleSuccessfullySubmitted) {
            return "Puzzle Updated"
        } else if (isPuzzleSuccessfullySubmitted === false) {
            return "Puzzle Error"
        } else if (isSubmitButtonClicked) {
            return "Updating puzzle..."
        } else {
            return "Edit puzzle"
        }
    }

    const isPuzzleEditable = () => {
        return user.permissions.includes("WRITE")
    }

    const riddleQuestionText = () => {
        if (puzzleStatus === "SUBMITTED") {
            return "Edit your riddle question."
        } else if (puzzleStatus === "QUEUED") {
            return "Riddle question"
        }
    }

    const riddleWordText = () => {
        if (puzzleStatus === "SUBMITTED") {
            return "Edit your riddle word."
        } else if (puzzleStatus === "QUEUED") {
            return "Riddle word"
        }
    }

    return isPuzzleError ? <div>
        <Navbar />
        <div className='error'>
            There was an error getting your puzzle.
            Please reload and try again.
        </div>
        <Footer />
    </div> :
        isLoading ? <div>
            <Loading />
        </div>
            : (
                <DragDropContext onDragEnd={onDragEnd}>
                    <Navbar />
                    <Droppable isDropDisabled={!isPuzzleEditable()} droppableId={"2"}>
                        {provided => (
                            <div className='grid-center' ref={provided.innerRef} {...provided.droppableProps}>
                                {isNewSubmition ?
                                    <div className='instructions-container'>
                                        <FontAwesomeIcon className="checkmark-icon-small" icon={faCheckCircle} />
                                        <p className='instructions-header'>Your puzzle was submitted!</p>
                                        <p
                                            style={{ marginTop: -10 }}
                                            className='edit-puzzle-text'>
                                            Your puzzle has been successfully submitted and is now awaiting review.
                                        </p>
                                        <p
                                            style={{ marginTop: -10 }}
                                            className='edit-puzzle-text'>
                                            Use this page to edit your puzzle. Once your puzzle is queued, it is no longer editable.
                                        </p>
                                    </div>
                                    : puzzleStatus === "SUBMITTED" ?
                                        <div className='instructions-container'>
                                            {isMobile ?
                                                <>
                                                    <p className='instructions-header-edit'>
                                                        Your puzzle is
                                                    </p>
                                                    <div className='flex-center'>
                                                        <p className='puzzle-status puzzle-status-short'> {puzzleStatus}</p>
                                                    </div>
                                                </>
                                                : <>
                                                    <p className='instructions-header-edit'>
                                                        Your puzzle is
                                                        <span className='puzzle-status'> {puzzleStatus}</span>
                                                    </p>
                                                </>}
                                            <p className='edit-puzzle-text'>
                                                Edit your puzzle as often as you'd like until your puzzle's status becomes "Queued."
                                            </p>
                                            <p
                                                style={{ marginTop: -10 }}
                                                className='edit-puzzle-text'>
                                                Once your puzzle is queued, it is no longer editable. So make sure to get your edits in now!
                                            </p>
                                        </div>
                                        : puzzleStatus === "QUEUED" ?
                                            <div className='instructions-container'>
                                                {isMobile ?
                                                    <>
                                                        <p className='instructions-header-edit'>
                                                            Your puzzle is
                                                        </p>
                                                        <div className='flex-center'>
                                                            <p className='puzzle-status'> {puzzleStatus}
                                                                <span className='for'> for</span> {new Date(releaseDate._seconds * 1000 + releaseDate._nanoseconds / 1000000).toLocaleDateString("en", {
                                                                    month: "long",
                                                                    day: "numeric",
                                                                    year: "numeric"
                                                                })}
                                                            </p>
                                                        </div>
                                                    </>
                                                    : <>
                                                        <p className='instructions-header-edit'>
                                                            Your puzzle is
                                                            <span className='puzzle-status'> {puzzleStatus}
                                                                <span className='for'> for</span> {new Date(releaseDate._seconds * 1000 + releaseDate._nanoseconds / 1000000).toLocaleDateString("en", {
                                                                    month: "long",
                                                                    day: "numeric",
                                                                    year: "numeric"
                                                                })}
                                                            </span>
                                                        </p>
                                                    </>}
                                                <p className='edit-puzzle-text'>
                                                    Your puzzle is Queued which means it can no longer be edited but can still <span onClick={() => navigate("/puzzles")} className='link'>be deleted</span>.
                                                </p>
                                                <p
                                                    style={{ marginTop: -10 }}
                                                    className='edit-puzzle-text'>
                                                    Once the queued date comes, your puzzle will be available for everyone to play!
                                                </p>
                                            </div>
                                            : puzzleStatus === "PLAYED" ?
                                                <div className='instructions-container'>
                                                    {isMobile ?
                                                        <>
                                                            <p className='instructions-header-edit'>
                                                                Your puzzle was
                                                            </p>
                                                            <div className='flex-center'>
                                                                <p className='puzzle-status'> {puzzleStatus}
                                                                    <span className='for'> on</span> {new Date(releaseDate._seconds * 1000 + releaseDate._nanoseconds / 1000000).toLocaleDateString("en", {
                                                                        month: "long",
                                                                        day: "numeric",
                                                                        year: "numeric"
                                                                    })}
                                                                </p>
                                                            </div>
                                                        </>
                                                        : <>
                                                            <p className='instructions-header-edit'>
                                                                Your puzzle was
                                                                <span className='puzzle-status'> {puzzleStatus}
                                                                    <span className='for'> on</span> {new Date(releaseDate._seconds * 1000 + releaseDate._nanoseconds / 1000000).toLocaleDateString("en", {
                                                                        month: "long",
                                                                        day: "numeric",
                                                                        year: "numeric"
                                                                    })}
                                                                </span>
                                                            </p>
                                                        </>}
                                                    <p className='edit-puzzle-text'>
                                                        Congratulations! Your puzzle was successfully played!
                                                    </p>
                                                    <p
                                                        style={{ marginTop: -10 }}
                                                        className='edit-puzzle-text'>
                                                        We hope you'll continue to make more puzzles so the creator of this game doesn't have to!
                                                    </p>
                                                </div> : puzzleStatus === "ACTIVE" ?
                                                    <div className='instructions-container'>
                                                        {isMobile ?
                                                            <>
                                                                <p className='instructions-header-edit'>
                                                                    Your puzzle is
                                                                </p>
                                                                <div className='flex-center'>
                                                                    <p className='puzzle-status puzzle-status-short'> {puzzleStatus}</p>
                                                                </div>
                                                            </>
                                                            : <>
                                                                <p className='instructions-header-edit'>
                                                                    Your puzzle is
                                                                    <span className='puzzle-status'> {puzzleStatus}</span>
                                                                </p>
                                                            </>}
                                                        <p className='edit-puzzle-text'>
                                                            Your puzzle's release date is today! Wohoooo!
                                                        </p>
                                                        <p
                                                            style={{ marginTop: -10 }}
                                                            className='edit-puzzle-text'>
                                                            Tell your friends and family to play your puzzle before it expires at Midnight tonight!
                                                        </p>
                                                    </div> : null}
                                <div className='question-container'>
                                    <div className='flex-center'>
                                        <p className='question-header'>{riddleQuestionText()}</p>
                                        <FontAwesomeIcon className="asterisk-icon" icon={faAsterisk} />
                                    </div>
                                    <div
                                        style={{ marginTop: 40 }}
                                        className='question-container-flex'>
                                        <button
                                            onClick={() => {
                                                if (!isPuzzleEditable()) { return }
                                                setQuestion("WHERE")
                                                setLocalStorage("question", "WHERE")
                                                if (isPuzzleSuccessfullySubmitted) {
                                                    userEditedPuzzleAfterSubmission()
                                                }
                                            }}
                                            className={question === 'WHERE' ? 'question-type selected' : 'question-type'}>
                                            Where am I?
                                        </button>
                                        <button
                                            onClick={() => {
                                                if (!isPuzzleEditable()) { return }
                                                setQuestion("WHAT")
                                                setLocalStorage("question", "WHAT")
                                                if (isPuzzleSuccessfullySubmitted) {
                                                    userEditedPuzzleAfterSubmission()
                                                }
                                            }}
                                            className={question === 'WHAT' ? 'question-type selected' : 'question-type'}>
                                            What am I?
                                        </button>
                                        <button
                                            onClick={() => {
                                                if (!isPuzzleEditable()) { return }
                                                setQuestion("WHO")
                                                setLocalStorage("question", "WHO")
                                                if (isPuzzleSuccessfullySubmitted) {
                                                    userEditedPuzzleAfterSubmission()
                                                }
                                            }}
                                            className={question === 'WHO' ? 'question-type selected' : 'question-type'}>
                                            Who am I?
                                        </button>
                                    </div>
                                </div>
                                <div className='question-container'>
                                    <div className='flex-center'>
                                        <p className='question-header'>{riddleWordText()}</p>
                                        <FontAwesomeIcon className="asterisk-icon" icon={faAsterisk} />
                                    </div>
                                    <input
                                        value={riddleWord}
                                        onChange={(e) => {
                                            if (!isPuzzleEditable()) { return }
                                            setRiddleWord(e.target.value)
                                            setLocalStorage("riddleWord", e.target.value)
                                            if (isPuzzleSuccessfullySubmitted) {
                                                userEditedPuzzleAfterSubmission()
                                            }
                                        }}
                                        onKeyDown={(e) => e.code === 'Space' || !allowedRiddleWordCharacters.includes(e.code) ? e.preventDefault() : null}
                                        maxLength={15}
                                        className='riddle-word-input' />
                                </div>
                                {puzzleStatus === "SUBMITTED" ? <div className='question-container'>
                                    <div className='grid-center'>
                                        <div className='flex-center'>
                                            <p className='question-header'>Edit your clues and clue descriptions.</p>
                                            <FontAwesomeIcon className="asterisk-icon edit-clues-asterisk" icon={faAsterisk} />
                                        </div>
                                        <p
                                            style={{ marginTop: -10 }}
                                            className='edit-puzzle-text'>
                                            Remember to look at the Puzzle Creation Guidelines when making your clues.
                                        </p>
                                        <p
                                            style={{ marginTop: -10 }}
                                            className='edit-puzzle-text'>
                                            And don't forget you can drag and drop the clues to change their order!
                                        </p>
                                    </div>
                                </div> : null}
                                {[...Array(12).keys()].map((index) => (
                                    <Clue
                                        handleSetClue={handleSetClue}
                                        key={clueIndexes[index]}
                                        index={index}
                                        clueText={clues[clueIndexes[index]].clueText}
                                        clueDescription={clues[clueIndexes[index]].clueDescription}
                                        id={clueIndexes[index]} />
                                ))}
                                {provided.placeholder}
                                {isPuzzleEditable() ?
                                    <div className='submission-container'>
                                        <div
                                            style={{ gap: 20 }}
                                            className='flex-center'>
                                            <FontAwesomeIcon className="caution-icon" icon={faTriangleExclamation} />
                                            <FontAwesomeIcon className="caution-icon" icon={faTriangleExclamation} />
                                            <FontAwesomeIcon className="caution-icon" icon={faTriangleExclamation} />
                                            <FontAwesomeIcon className="caution-icon" icon={faTriangleExclamation} />
                                            <p className='instructions-header'>Review the Submission Rules</p>
                                            <FontAwesomeIcon className="caution-icon" icon={faTriangleExclamation} />
                                            <FontAwesomeIcon className="caution-icon" icon={faTriangleExclamation} />
                                            <FontAwesomeIcon className="caution-icon" icon={faTriangleExclamation} />
                                            <FontAwesomeIcon className="caution-icon" icon={faTriangleExclamation} />
                                        </div>
                                        <p
                                            style={{ fontWeight: 500, fontSize: 21, marginTop: 5 }}
                                            className='instruction-text-submition text-submission-title'>
                                            How Submission Works
                                        </p>
                                        <p className='instruction-text-submition'><span className='instruction-number'>1.</span>
                                            When you submit your puzzle, you may continue to edit it until your puzzle is <i>Queued.</i>
                                        </p>
                                        <p className='instruction-text-submition'><span className='instruction-number'>2.</span>
                                            You are encouraged to enhance your clues if something pops into your head after submission!
                                        </p>
                                        <p className='instruction-text-submition'><span className='instruction-number'>3.</span>
                                            Once your puzzle is <i>Queued</i>, it will be locked (no more edits) and given a release date.
                                        </p>
                                        <p className='instruction-text-submition'><span className='instruction-number'>5.</span>
                                            Once the release date comes, your puzzle will be available for everyone to play!
                                        </p>
                                        <p
                                            style={{ fontWeight: 500, fontSize: 21, marginTop: 5 }}
                                            className='instruction-text-submition text-submission-title'>
                                            Submission Rules
                                        </p>
                                        <p
                                            className='instruction-text-submition'>
                                            Your puzzle must abide by the <span className='indent'>following to get accepted:</span>
                                        </p>
                                        <p className='instruction-text-submition'><span className='instruction-number'>1.</span>
                                            Have no harmful or offensive <span className='mobile-indent'>language.</span>
                                        </p>
                                        <p className='instruction-text-submition'><span className='instruction-number'>2.</span>
                                            Follow the <span onClick={() => navigate("/guidelines")} className='link'>Puzzle Creation <span className='mobile-indent'>Guidelines </span> (mentioned above).</span>
                                        </p>
                                        <div
                                            style={{ gap: 10 }}
                                            className='flex-center checkbox-container'>
                                            <input
                                                onChange={() => setIsBoxChecked(!isBoxChecked)}
                                                type="checkbox"
                                                className='checkbox' />
                                            <div style={{ display: "flex" }}>
                                                <p className='checkbox-text'> I have read through the <span onClick={() => navigate("/guidelines")} className='link'>Puzzle Creation Guidelines</span> and ensured my puzzle follows its instructions.
                                                    <br></br>
                                                    <span className='checkbox-text-second-line'>I understand that if my puzzle does not follow the guidelines it may not be approved.</span>
                                                </p>
                                                <FontAwesomeIcon className="asterisk-icon-checkbox" icon={faAsterisk} />
                                            </div>
                                        </div>
                                        <div>
                                            <p className='name-instructions'>
                                                If you would like your name to appear on your puzzle, please write your name below.
                                            </p>
                                            <input
                                                value={puzzleAuthor}
                                                onChange={(e) => {
                                                    setPuzzleAuthor(e.target.value)
                                                    setLocalStorage("puzzleAuthor", e.target.value)
                                                }}
                                                maxLength={50}
                                                placeholder={isMobile ? "Write your name here!" : "Write your name here! Otherwise the puzzle author will remain anonymous."}
                                                className='name-input' />
                                        </div>
                                        <button
                                            onClick={handleUpdatePuzzle}
                                            className={getSubmitButtonClassName()}>
                                            {getSubmitButtonText()}
                                        </button>
                                        {isPuzzleSuccessfullySubmitted ?
                                            <div>
                                                <FontAwesomeIcon className="checkmark-icon" icon={faCheckCircle} />
                                                <p className='submit-success-message'>Your puzzle was successfully updated!</p>
                                            </div> : null}
                                        {isPuzzleSuccessfullySubmitted === false ?
                                            <div>
                                                <FontAwesomeIcon className="xmark-icon" icon={faXmarkCircle} />
                                                <p className='submit-success-message'>There was an error updating your puzzle</p>
                                                <p>Please reload the page and resubmit.</p>
                                            </div> : null}
                                    </div> : null}
                            </div>
                        )}
                    </Droppable>
                    <Footer />
                </DragDropContext >
            );
}

export default ReviewPuzzle