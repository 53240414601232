import React, { createContext, useState, useEffect } from 'react'
import { getAuth, onAuthStateChanged } from "../firestore";
import { getUser } from '../Api/api';

export const ContextProvider = createContext();

export const Context = ({ children }) => {

    const [user, setUser] = useState({ isUserAuthenticated: undefined })

    const auth = getAuth();

    const setStateOrLocalStorage = (value, id) => {
        const localStorageValue = JSON.parse(localStorage.getItem(id))
        // If the local storage is set, use that
        if (localStorageValue !== null && localStorageValue !== undefined) {
            return localStorageValue
        }
        // Otherwise just use the default state
        return value
    }

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                // If the user is trying to sign in, set local storage so we know
                getUser((result) => {
                    if (result.success) {
                        const isUserAuthenticated = true
                        setUser({ ...result.data, isUserAuthenticated })
                    }
                })
            } else {
                setUser({ isUserAuthenticated: false })
            }
        });
    }, [])

    //Pass the user object to the context. 
    //Auth context will wrap around every component in app.js
    return (
        <ContextProvider.Provider value={{
            user, setUser, setStateOrLocalStorage
        }}>
            {children}
        </ContextProvider.Provider>)
}