import React, { useContext, useState } from 'react'
import { ContextProvider } from '../context/index';
import Loading from '../Components/Loading';
import { getAuth, onAuthStateChanged } from "../firestore";


const BaseRoute = ({ children }) => {

    const auth = getAuth();

    const [isLoading, setIsLoading] = useState(false)

    const { user } = useContext(ContextProvider)

    onAuthStateChanged(auth, (user) => {
        if (user) {
            setIsLoading(true)
        } else {
            setIsLoading(false)
        }
    });


    // If the user is authenticated send them to /create
    // If the user is not authenticated show base component (ie. the homepage)
    // Otherwise, show loading

    return isLoading && user.isUserAuthenticated === false ? <Loading /> : user.isUserAuthenticated === true ?
        window.location.href = "/create" :
        user.isUserAuthenticated === false ?
            children : <Loading />
}

export default BaseRoute