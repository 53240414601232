import React, { useEffect } from 'react'
import Navbar from './Navbar'
import { TypeAnimation } from 'react-type-animation';
import { signInWithPopup, GoogleAuthProvider, getAuth } from "../firestore";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGoogle } from '@fortawesome/free-brands-svg-icons'
import "../Styles/homepage.css"

const Homepage = () => {

    const signInWithGoogle = () => {
        const provider = new GoogleAuthProvider();
        const auth = getAuth();
        signInWithPopup(auth, provider);
    }

    useEffect(() => {
        // Clear all local storage
        // when the user logs out
        localStorage.clear();
    }, [])

    return (
        <>
            <Navbar />
            <div className='grid-center'>
                <div className='directions-animation'>
                    <div className='animation-text'>
                        <TypeAnimation
                            sequence={[
                                'Come up with your riddle word.',
                                1000,
                                'Create your clues.',
                                1000,
                                'Figure out your clue order.',
                                1000,
                                'Review and submit your puzzle!',
                                1000,
                            ]}
                            cursor={true}
                            repeat={Infinity}
                        />
                    </div>
                </div >
                <p className='homepage-header-text'>Let's Make a Puzzle!</p>
                <p className='homepage-text'>Sign in to get started.</p>
                <div
                    style={{ gap: 20 }}
                    className='flex-center'>
                    <button
                        onClick={() => signInWithGoogle()}
                        style={{ gap: 5 }}
                        className='sign-in-button flex-center'>
                        <FontAwesomeIcon className="google-icon" icon={faGoogle} />
                        <p className='sign-in-type-text'>Google</p>
                    </button>
                </div>
            </div>
        </>
    )
}

export default Homepage