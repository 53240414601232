import React, { useContext, useState, useRef, useEffect } from 'react'
import { ContextProvider } from '../context/index';
import { signInWithPopup, GoogleAuthProvider, getAuth, signOut } from "../firestore";
import { useNavigate, useLocation } from "react-router-dom"
import "../Styles/navbar.css"
import Hamburger from 'hamburger-react'

const Navbar = () => {

    const { user } = useContext(ContextProvider)

    const navigate = useNavigate()

    const location = useLocation();

    const [isOpen, setOpen] = useState(false)

    const [isAccountCircleClicked, setIsAccountCircleClicked] = useState(false);

    const [isHamburgerClicked, setIsHamburgerClicked] = useState(false)

    const signInWithGoogle = () => {
        const provider = new GoogleAuthProvider();
        const auth = getAuth();
        signInWithPopup(auth, provider);
    }

    const closeHamburgerAndSideBar = () => {
        setIsHamburgerClicked(false)
        setOpen(false)
    }

    return (
        <div
            className='navbar'>
            <div
                onClick={() => setIsHamburgerClicked(!isHamburgerClicked)}
                className='hamburger'>
                <Hamburger
                    color="white"
                    toggled={isOpen} toggle={setOpen}
                    className="hamburger"
                />
            </div>
            {isHamburgerClicked ?
                <div className='side-bar'>
                    <p className='side-bar-header'>J&A Games</p>
                    <hr style={{ marginTop: -5 }} />
                    {user.isUserAuthenticated && user?.permissions?.length > 0 ?
                        <p
                            onClick={() => navigate("/admin")}
                            className='side-bar-text'>
                            Admin Dashboard
                        </p>
                        : null}
                    {user.isUserAuthenticated ? <p
                        onClick={() => location.pathname.includes("/puzzles") ? closeHamburgerAndSideBar()
                            : navigate("/puzzles")}
                        className='side-bar-text'>
                        My Puzzles
                    </p> : null}
                    {user.isUserAuthenticated ? <p
                        onClick={() => location.pathname.includes("/create") ? closeHamburgerAndSideBar()
                            : navigate("/create")}
                        className='side-bar-text'>
                        Create Puzzle
                    </p> : null}
                    {user.isUserAuthenticated ? <p
                        onClick={() => location.pathname.includes("/guidelines") ? closeHamburgerAndSideBar()
                            : navigate("/guidelines")}
                        className='side-bar-text'>
                        Puzzle Creation Guidelines
                    </p> : null}
                    <a
                        href={"https://riddley.us"}
                        target='_blank'
                        className='play-the-riddley' rel="noreferrer">
                        Play The Riddley
                    </a>
                    <p
                        onClick={() => {
                            if (user.isUserAuthenticated) {
                                const auth = getAuth();
                                signOut(auth)
                                window.location.href = "/"
                            }
                            else {
                                signInWithGoogle()
                            }
                        }}
                        className='side-bar-text logout'>
                        {user.isUserAuthenticated ? "Logout" : "Login or Sign Up"}
                    </p>
                </div>
                : null}

            <p
                className='title'>
                <span
                    className='to-create-page'
                    onClick={() => navigate("/create")}>
                    Riddley
                </span>
            </p>
            {user.isUserAuthenticated ?
                <div
                    onClick={() => setIsAccountCircleClicked(true)}
                    className='user-profile'>
                    <p className="user-profile-initials">
                        {user.userName.split(" ")[0][0].toUpperCase()}{user.userName.split(" ")[1][0].toUpperCase()}
                    </p>
                </div> :
                <button
                    onClick={() => signInWithGoogle()}
                    className='sign-up-or-login-button'>
                    Login or Sign Up
                </button>}
            {isAccountCircleClicked ? <AccountDropdown setIsAccountCircleClicked={setIsAccountCircleClicked} user={user} /> : null}
        </div>
    )
}

export default Navbar

function AccountDropdown({ setIsAccountCircleClicked, user }) {

    let accountDropdownRef = useRef();

    useEffect(() => {
        let closeDropdownHandler = (event) => {
            if (!accountDropdownRef.current.contains(event.target)) {
                setIsAccountCircleClicked(false);
            }
        }
        document.addEventListener("mousedown", closeDropdownHandler);
        return () => {
            document.removeEventListener("mousedown", closeDropdownHandler);
        }
    })

    const closeModal = () => {
        setIsAccountCircleClicked(false);
    }


    return (
        <div ref={accountDropdownRef} className="modal-style-user">
            <button onClick={closeModal} className="close-modal">&times;</button>
            <div className="account-icon-in-modal">
                <p className="initials">{user.userName.split(" ")[0][0]}{user.userName.split(" ")[1][0]}</p>
            </div>
            <p className="username">{user.userName}</p>
            <p className="email">{user.userEmail}</p>
            <button className="manage-account-button">Manage my account</button>
            <br></br>
            <hr></hr>
            <button onClick={() => {
                const auth = getAuth();
                signOut(auth)
                window.location.href = "/"
            }} className="sign-out-button">Sign-out</button>
        </div >
    )
}