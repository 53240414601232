import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import "../Styles/clue.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAsterisk } from "@fortawesome/pro-regular-svg-icons";

const Clue = ({ index, clueText, clueDescription, id, handleSetClue }) => {

    const getPlaceholder = () => {
        if (index === 0) {
            return "I'm the first clue, so make me the most difficult!"
        } else if (index === 1) {
            return "Clue 2"
        } else if (index === 2) {
            return "Clue 3"
        } else if (index === 3) {
            return "Clue 4"
        } else if (index === 4) {
            return "Clue 5"
        } else if (index === 5) {
            return "Clue 6"
        } else if (index === 6) {
            return "Clue 7"
        } else if (index === 7) {
            return "Clue 8"
        } else if (index === 8) {
            return "Clue 9"
        } else if (index === 9) {
            return "Clue 10"
        } else if (index === 10) {
            return "Clue 11"
        } else if (index === 11) {
            return "I'm the last clue, so make me the easiest!"
        }
    }

    const getClueDescriptionPlaceholder = () => {
        if (index === 0) {
            return "Clue Description 1. I'm a clue description! I describe the relevance of the clue to the riddle word. Check the guidelines for more info."
        } else if (index === 1) {
            return "Clue Description 2"
        } else if (index === 2) {
            return "Clue Description 3"
        } else if (index === 3) {
            return "Clue Description 4"
        } else if (index === 4) {
            return "Clue Description 5"
        } else if (index === 5) {
            return "Clue Description 6"
        } else if (index === 6) {
            return "Clue Description 7"
        } else if (index === 7) {
            return "Clue Description 8"
        } else if (index === 8) {
            return "Clue Description 9"
        } else if (index === 9) {
            return "Clue Description 10"
        } else if (index === 10) {
            return "Clue Description 11"
        } else if (index === 11) {
            return "Clue Description 12"
        }
    }

    const handleClueChange = (e) => {
        const newClueText = e.target.value
        handleSetClue(index, newClueText, "TEXT")
    }

    const handleDescriptionChange = (e) => {
        const newClueDescription = e.target.value
        handleSetClue(index, newClueDescription, "DESCRIPTION")
    }

    return (
        <Draggable draggableId={id} index={index}>
            {provided => (
                <div
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                    className='clues'>
                    <div className={`clue clue-${index}`}>
                        <textarea
                            value={clueText}
                            maxLength={150}
                            onChange={handleClueChange}
                            className='textarea'
                            placeholder={getPlaceholder()} />
                        <FontAwesomeIcon className="asterisk-icon-in-clue" icon={faAsterisk} />
                    </div>
                    <div className={`clue clue-${index} clue-description`}>
                        <textarea
                            value={clueDescription}
                            maxLength={150}
                            onChange={handleDescriptionChange}
                            placeholder={getClueDescriptionPlaceholder()}
                            className='textarea' />
                        <FontAwesomeIcon className="asterisk-icon-in-clue" icon={faAsterisk} />
                    </div>
                </div>
            )}
        </Draggable>
    );
}

export default Clue