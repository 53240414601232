import CreatePuzzle from './CreatePuzzle';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { Context } from '../context';
import PrivateRoute from '../utils/privateRoute';
import BaseRoute from '../utils/BaseRoute';
import AdminRoute from '../utils/AdminRoute';
import Homepage from './Homepage';
import EditPuzzle from './EditPuzzle';
import MyPuzzles from './MyPuzzles';
import AdminDashboard from './AdminDashboard';
import ReviewPuzzle from './ReviewPuzzle';
import Guidelines from './Guidelines';

function App() {
  return (
    <Context>
      <Router>
        <Routes>
          <Route
            exact path="/"
            element={
              <BaseRoute>
                <Homepage />
              </BaseRoute>
            }
          />
          <Route
            exact path="/create"
            element={
              <PrivateRoute>
                <CreatePuzzle />
              </PrivateRoute>
            }
          />
          <Route
            exact path="/guidelines"
            element={
              <PrivateRoute>
                <Guidelines />
              </PrivateRoute>
            }
          />
          <Route
            exact path="/puzzle/:puzzleId"
            element={
              <PrivateRoute>
                <EditPuzzle />
              </PrivateRoute>
            }
          />
          <Route
            exact path="/puzzles"
            element={
              <PrivateRoute>
                <MyPuzzles />
              </PrivateRoute>
            }
          />
          <Route
            exact path="/admin"
            element={
              <AdminRoute>
                <AdminDashboard />
              </AdminRoute>
            }
          />
          <Route
            exact path="/admin/puzzle/:puzzleId"
            element={
              <AdminRoute>
                <ReviewPuzzle />
              </AdminRoute>
            }
          />
        </Routes>
      </Router >
    </Context>
  );
}

export default App;
