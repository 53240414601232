import { getAuth, onAuthStateChanged } from "firebase/auth";
const { API_ROOT_URL } = require("../../../configs/activebuildconfig.json")

/**
 * CONTROLLER - admins
 * FUNCTION - getCollectionPuzzles
 * VERIFY - admin
 * @param {*} collection The collection from which we are trying to get the documents
 * @param {*} callback callback function
 * @returns The collection docs
 */

export const getCollectionPuzzles = async (collection, callback) => {
    const auth = getAuth();
    onAuthStateChanged(auth, async (user) => {
        const token = await user?.getIdToken(false);
        const response = await fetch(`${API_ROOT_URL}/admins/get_collection_puzzles`, {
            method: "PUT",
            headers: {
                Accept: "application/json",
                "Content-type": "application/json",
            },
            body: JSON.stringify({
                token,
                collection
            }),
        })
        const result = await response.json();
        callback(result)
    })
}