


import React from "react"
import "../Styles/footer.css"
import { Link, } from "react-router-dom"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXTwitter, faFacebook, faInstagram, faYoutube, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { faCopyright } from "@fortawesome/pro-light-svg-icons";

const Footer = () => {


    return (
        <>
            <div className="footer-container">
                <div className="footer-shape">

                </div>
                <p className="footer-slogan">It's sort of like a riddle game.</p>
                <div className="flex footer-buttons">
                    <button
                        className="footer-button-1">
                        Riddley
                    </button>
                </div>
            </div>
            <div className="footer-details">
                <div>
                    <p className="footer-details-header">Product</p>
                    <Link style={{ textDecoration: "none" }} to="/about-us"><p className="footer-details-list">Features</p></Link>
                    <Link style={{ textDecoration: "none" }} to="/about-us"><p className="footer-details-list">Enterprise</p></Link>
                    <Link style={{ textDecoration: "none" }} to="/about-us"><p className="footer-details-list">Solutions</p></Link>
                    <Link style={{ textDecoration: "none" }} to="/about-us"><p className="footer-details-list">Releases</p></Link>
                </div>
                <div>
                    <p className="footer-details-header">Pricing</p>
                    <Link style={{ textDecoration: "none" }} to="/about-us"><p className="footer-details-list">Plans</p></Link>
                    <Link style={{ textDecoration: "none" }} to="/about-us"><p className="footer-details-list">Free Version</p></Link>
                    <Link style={{ textDecoration: "none" }} to="/about-us"><p className="footer-details-list">Enterprise Pricing</p></Link>
                </div>
                <div>
                    <p className="footer-details-header">Resources</p>
                    <Link style={{ textDecoration: "none" }} to="/about-us"><p className="footer-details-list">Partners</p></Link>
                    <Link style={{ textDecoration: "none" }} to="/about-us"><p className="footer-details-list">Developers</p></Link>
                    <Link style={{ textDecoration: "none" }} to="/about-us"><p className="footer-details-list">Community</p></Link>
                    <Link style={{ textDecoration: "none" }} to="/about-us"><p className="footer-details-list">Help Center</p></Link>
                    <Link style={{ textDecoration: "none" }} to="/about-us"><p className="footer-details-list">Events</p></Link>
                    <Link style={{ textDecoration: "none" }} to="/about-us"><p className="footer-details-list">Blog</p></Link>
                </div>
                <div>
                    <p className="footer-details-header">Company</p>
                    <Link style={{ textDecoration: "none" }} to="/about-us"><p className="footer-details-list">About us</p></Link>
                    <Link style={{ textDecoration: "none" }} to="/about-us"><p className="footer-details-list">News</p></Link>
                    <Link style={{ textDecoration: "none" }} to="/about-us"><p className="footer-details-list">Careers</p></Link>
                    <Link style={{ textDecoration: "none" }} to="/about-us"><p className="footer-details-list">Investors</p></Link>
                    <Link style={{ textDecoration: "none" }} to="/terms-and-conditions"><p className="footer-details-list">Terms and conditions</p></Link>


                </div>
            </div>
            <hr className="footer-horizontal-line" />
            <div className="flex-2 footer-bottom-bar-container">
                <div className="flex-2">
                    <p className="footer-bottom-bar">Status</p>
                    <p className="footer-bottom-bar">Privacy</p>
                    <p className="footer-bottom-bar">Terms</p>
                    <p className="footer-bottom-bar">Cookie Preferences</p>
                    <p className="footer-bottom-bar">Contact Us</p>
                    <p className="footer-bottom-bar">Change Region</p>
                </div>
                <div className="flex-3">
                    <FontAwesomeIcon className="social-media-icon" icon={faXTwitter} />
                    <FontAwesomeIcon className="social-media-icon" icon={faFacebook} />
                    <FontAwesomeIcon className="social-media-icon" icon={faInstagram} />
                    <FontAwesomeIcon className="social-media-icon" icon={faYoutube} />
                    <FontAwesomeIcon className="social-media-icon" icon={faLinkedin} />
                </div>
            </div>
            <div className="flex-4 footer-copyright-notice">
                {/* <FontAwesomeIcon className="copy-right-icon" icon={faCopyright} /> */}
                <p className="copyright-notice">2024 Riddley Technologies. All rights reserved.</p>

            </div>
        </>
    )
}

export default Footer;