import { getAuth, onAuthStateChanged } from "firebase/auth";
const { API_ROOT_URL } = require("../../../configs/activebuildconfig.json")

/**
 * CONTROLLER - users
 * FUNCTION - getPuzzles
 * VERIFY - verified user
 * @param {*} callback callback function
 * @returns User Data
 */

export const getPuzzles = async (callback) => {
    const auth = getAuth();
    onAuthStateChanged(auth, async (user) => {
        if (user) {
            const token = await user?.getIdToken(false);
            const response = await fetch(`${API_ROOT_URL}/users/get_puzzles`, {
                method: "PUT",
                headers: {
                    Accept: "application/json",
                    "Content-type": "application/json",
                },
                body: JSON.stringify({
                    token,
                }),
            })
            const result = await response.json();
            callback(result)
        }
    })
}