import React from 'react'
import Navbar from './Navbar'
import Footer from './Footer'
import "../Styles/guideline.css"

const Guidelines = () => {
    return <div>
        <Navbar />
        <div className='grid-center guideline-container'>
            <p className='guideline-title'>Follow this guide. Submit your puzzle. Get Approved 😎</p>
            <div className='rules-container'>
                <p className='rule-number'>Rule 1</p>
                <p className='rule-title'>
                    Your <span className='highlight'>Riddle Question</span> must match your  <span className='highlight'>Riddle Word</span>.
                </p>
                <p className='rule-description'>
                    <i>Who am I &nbsp; &nbsp; &nbsp;&nbsp; &nbsp;➡️ &nbsp; &nbsp; Person (Ex. Gandhi)</i><br></br>
                    <i>What am I &nbsp; &nbsp;&nbsp; &nbsp;➡️ &nbsp; &nbsp; Thing (Ex. Wiffle Ball)</i><br></br>
                    <i>Where am I &nbsp; &nbsp;➡️ &nbsp; &nbsp;  Place (Ex. Australia)</i><br></br>
                    If your Riddle Question is "Who am I" then your Riddle Word needs to be a person (It can be a real or fake person). If your Riddle Question is "What am I" then your Riddle Word needs to be a thing (This is the broadest category. Anything that's not a person or place will be in this category). If your Riddle Question is "Where am I" then your Riddle Word needs to be a location (It can be a real or fake location).
                </p>
                <p className='rule-number'>Rule 2</p>
                <p className='rule-title'>
                    Do not make your <span className='highlight'>Riddle Word</span> too  <span className='highlight'>vague</span> or too  <span className='highlight'>niche</span>.
                </p>
                <p className='rule-description'>
                    <i>Too Vague: House </i><br></br>
                    <i>Too Niche: David Blaine</i> <br></br>
                    A house can also be called a home or a shelter. It's a general noun that can be used in many contexts. Would 75 out of 100 people on the streets of New York City know who David Blaine is? No. The clue is too niche. The point of this game is for everyone to have the ability to solve the riddle so long as they can figure out the clues. They can't solve the riddle if they don't know the riddle word.
                </p>
                <p className='rule-number'>Rule 3</p>
                <p className='rule-title'>
                    Your <span className='highlight'>Clues</span> require  <span className='highlight'>riddle-solving</span>.
                </p>
                <p className='rule-description'>
                    <i>Example Riddle Word: <b>iPhone</b> </i><br></br>
                    <i>Bad Clue: Released in 2007 by Steve Jobs.</i> <br></br>
                    <i>Good Clue: Made a blackberry go extinct.</i> <br></br>
                    Clues should not solely be a fact, although they can be based on facts. Good clues require one or more of a play on words, a double entendre, misdirection, anthropomorphism, etc. The best clues should stump a player enough that they have to think about it, but not so much that they can never figure it out. The reaction to a good clue should be "Ohhhh, that's really neat! I totally should have gotten that!" Be creative with your clues.
                </p>
                <p className='rule-number'>Rule 4</p>
                <p className='rule-title'>
                    Your <span className='highlight'>Clues</span> must get  <span className='highlight'>easier</span>.
                </p>
                <p className='rule-description'>
                    <i>Clues 1-3 &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;➡️ &nbsp; <b>Very difficult</b> (Few people should solve the riddle here)</i><br></br>
                    <i>Clues 4-6 &nbsp; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;➡️ &nbsp; &nbsp;  <b>Difficult</b> (Some people should solve the riddle here)</i><br></br>
                    <i>Clues 7-9 &nbsp; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;➡️ &nbsp; &nbsp;  <b>Moderate</b> (Most people should solve the riddle here)</i><br></br>
                    <i>Clues 10-12 &nbsp; &nbsp;&nbsp;➡️ &nbsp; &nbsp;  <b>Easy</b> (Everyone should be able to solve the riddle here)</i><br></br>
                    The clues must get easier as the game progresses. The hardest clues are the first ones shown and the easiest clues are the last shown. The first three clues should be very difficult for solving the riddle. By the last clue, most people should be able to solve the riddle.
                </p>
                <p className='rule-number'>Rule 5</p>
                <p className='rule-title'>
                    Write <span className='highlight'>unambiguous Clue</span> <span className='highlight'> Descriptions</span>.
                </p>
                <p className='rule-description'>
                    <i>Example Clue: Made a blackberry go extinct.</i> <br></br>
                    <i>Bad Clue Description: Other phone brand.</i> <br></br>
                    <i>Good Clue Description: This clue was a play on words. Blackberry is not a fruit, but rather a competitor phone brand to the iPhone which was eventually discontinued or made "extinct" due to the iPhone's market dominance.</i> <br></br>
                    Clue Descriptions must explain the "catch" of the clue as well as any factual knowledge that was required to understand the clue. There should be no doubt about the meaning of the clue after reading the clue description.
                </p>
            </div>
        </div>
        <Footer />
    </div>
}

export default Guidelines