// Import the functions you need from the SDKs you need
import firebase from "firebase/compat/app"
import {
    getAuth, signInWithPopup,
    GoogleAuthProvider, onAuthStateChanged, signOut
} from "firebase/auth";
const activeBuildConfig = require("./configs/activebuildconfig.json")

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: activeBuildConfig.API_KEY,
    authDomain: activeBuildConfig.AUTH_DOMAIN,
    projectId: activeBuildConfig.PROJECT_ID,
    storageBucket: activeBuildConfig.STORAGE_BUCKET,
    messagingSenderId: activeBuildConfig.MESSAGING_SENDER_ID,
    appId: activeBuildConfig.APP_ID,
    measurementId: activeBuildConfig.MEASUREMENT_ID
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig)

export {
    firebase,
    getAuth,
    signInWithPopup,
    GoogleAuthProvider,
    onAuthStateChanged,
    signOut,
    app
}