import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom"
import Navbar from './Navbar'
import Footer from './Footer'
import { getCollectionPuzzles } from '../Api/api'
import { deletePuzzleForAdmin } from '../Api/api'
import { approvePuzzle } from '../Api/api'
import { rejectPuzzle } from '../Api/api'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faXmarkCircle } from "@fortawesome/pro-regular-svg-icons";
import "../Styles/adminDashboard.css"

const AdminDashboard = () => {

    const [backlogPuzzles, setBacklogPuzzles] = useState([])

    const [queuedPuzzles, setQueuedPuzzles] = useState([])

    const [activePuzzles, setActivePuzzles] = useState([])

    const [isActionItemClicked, setIsActionItemClicked] = useState({ puzzleType: "", index: -1, action: null })

    const [actionItemResponse, setActionItemResponse] = useState({ puzzleType: "", result: null, index: -1, action: null })


    useEffect(() => {
        fetchPuzzles()
    }, [])

    const fetchPuzzles = (afterActionTaken = false) => {
        getCollectionPuzzles("backlogPuzzles", (result) => {
            if (result.success) {
                setBacklogPuzzles(result.data)
                if (afterActionTaken) {
                    setIsActionItemClicked({ puzzleType: "", index: -1, action: null })
                    setActionItemResponse({ puzzleType: "", result: null, index: -1, action: null })
                }
            }
        });
        getCollectionPuzzles("queuedPuzzles", (result) => {
            if (result.success) {
                setQueuedPuzzles(result.data)
                if (afterActionTaken) {
                    setIsActionItemClicked({ puzzleType: "", index: -1, action: null })
                    setActionItemResponse({ puzzleType: "", result: null, index: -1, action: null })
                }
            }
        })
        getCollectionPuzzles("activePuzzle", (result) => {
            if (result.success) {
                setActivePuzzles(result.data)
                if (afterActionTaken) {
                    setIsActionItemClicked({ puzzleType: "", index: -1, action: null })
                    setActionItemResponse({ puzzleType: "", result: null, index: -1, action: null })
                }
            }
        })
    }

    const getBackgroundColor = (index) => {
        if (index % 2 === 0) {
            return "#d2d0d0"
        }
        return "#f1f1f1"
    }

    const handleAction = (puzzleType, action, puzzleId, userEmail, index) => {
        if (action === "Approve") {
            approvePuzzle(puzzleId, userEmail, (result) => {
                if (result.success) {
                    setActionItemResponse({ puzzleType, result: "SUCCESS", index, action })
                }
                else {
                    setActionItemResponse({ puzzleType, result: "ERROR", index, action })
                }
            })
        } else if (action === "Reject") {
            rejectPuzzle(puzzleId, userEmail, (result) => {
                if (result.success) {
                    setActionItemResponse({ puzzleType, result: "SUCCESS", index, action })
                }
                else {
                    setActionItemResponse({ puzzleType, result: "ERROR", index, action })
                }
            })
        } else if (action === "Delete") {
            deletePuzzleForAdmin(puzzleId, userEmail, (result) => {
                if (result.success) {
                    setActionItemResponse({ puzzleType, result: "SUCCESS", index, action })
                }
                else {
                    setActionItemResponse({ puzzleType, result: "ERROR", index, action })
                }
            })
        }
        setTimeout(() => {
            const afterActionTaken = true
            fetchPuzzles(afterActionTaken);
        }, 3000);

    }
    return (
        <>
            <Navbar />
            <div className='active-puzzle-container'>
                <p className='dashboard-header'>Active</p>
                {activePuzzles.length == 0 ? "No active puzzle" : null}
                {activePuzzles.map((puzzle, index) => {
                    return <div
                        key={puzzle.releaseDate._seconds}
                        style={{ backgroundColor: getBackgroundColor(index) }}
                        className='puzzle-summary'>
                        {actionItemResponse.index === index && actionItemResponse.puzzleType === "ACTIVE" ? <div
                            className='summary-content'>
                            <p className='are-you-sure-text'>
                                {actionItemResponse.result === "SUCCESS" ? `${puzzle.riddleWord} was successfully ${actionItemResponse.action}${actionItemResponse.action === "Reject" ? "ed" : "d"}` :
                                    `There was an error when trying to ${actionItemResponse.action} ${puzzle.riddleWord}`}
                            </p>
                            {actionItemResponse.result === "SUCCESS" ?
                                <FontAwesomeIcon className="checkmark-icon admin-icon" icon={faCheckCircle} /> :
                                <FontAwesomeIcon className="xmark-icon admin-icon" icon={faXmarkCircle} />}
                        </div>
                            : isActionItemClicked.index === index && isActionItemClicked.puzzleType === "ACTIVE" ? <>
                                <p className='are-you-sure-text'>Are you sure you want to {isActionItemClicked.action} {puzzle.riddleWord}?</p>
                                <div
                                    className='summary-content'
                                    style={{ gap: 60 }}>
                                    <button onClick={() => setIsActionItemClicked({ puzzleType: "", index: -1, action: null })} className='admin-button'>Cancel</button>
                                    <button onClick={() => handleAction("ACTIVE", isActionItemClicked.action, puzzle.puzzleId, puzzle.userEmail, index)} className='admin-button'>{isActionItemClicked.action}</button>
                                </div>
                            </>
                                :
                                <>
                                    <button className='riddle-word-text'>{puzzle.riddleWord}</button>
                                    <div style={{ textAlign: 'center', marginTop: 10, marginBottom: -10 }}>
                                        <b>Release Date:</b> {new Date(puzzle.releaseDate._seconds * 1000 + puzzle.releaseDate._nanoseconds / 1000000).toLocaleDateString("en", {
                                            weekday: "short",
                                            month: "long",
                                            day: "numeric",
                                            year: "numeric"
                                        })}</div>
                                    <div className='summary-content'>
                                        <Link to={`/admin/puzzle/${puzzle.puzzleId}?puzzleAuthorEmail=${puzzle.userEmail}`} target="_blank" >
                                            <button className='admin-button'>Review</button>
                                        </Link>
                                        <button onClick={() => setIsActionItemClicked({ puzzleType: "ACTIVE", index, action: "Delete" })} className='admin-button'>Delete</button>
                                    </div>
                                </>}
                    </div>
                }).sort((a, b) => a.key - b.key)}
            </div>
            <div className='admin-dashboard-container'>
                <div>
                    <p className='dashboard-header'>Backlog</p>
                    {backlogPuzzles.length == 0 ? "No backlog puzzles" : null}
                    {backlogPuzzles.map((puzzle, index) => {
                        return <div
                            key={puzzle.createdAt._seconds}
                            style={{ backgroundColor: getBackgroundColor(index) }}
                            className='puzzle-summary'>
                            {actionItemResponse.index === index && actionItemResponse.puzzleType === "BACKLOG" ? <div
                                className='summary-content'>
                                <p className='are-you-sure-text'>
                                    {actionItemResponse.result === "SUCCESS" ? `${puzzle.riddleWord} was successfully ${actionItemResponse.action}${actionItemResponse.action === "Reject" ? "ed" : "d"}` :
                                        `There was an error when trying to ${actionItemResponse.action} ${puzzle.riddleWord}`}
                                </p>
                                {actionItemResponse.result === "SUCCESS" ?
                                    <FontAwesomeIcon className="checkmark-icon admin-icon" icon={faCheckCircle} /> :
                                    <FontAwesomeIcon className="xmark-icon admin-icon" icon={faXmarkCircle} />}
                            </div>
                                : isActionItemClicked.index === index && isActionItemClicked.puzzleType === "BACKLOG" ? <>
                                    <p className='are-you-sure-text'>Are you sure you want to {isActionItemClicked.action} {puzzle.riddleWord}?</p><div
                                        className='summary-content'
                                        style={{ gap: 60 }}>
                                        <button onClick={() => setIsActionItemClicked({ puzzleType: "", index: -1, action: null })} className='admin-button'>Cancel</button>
                                        <button onClick={() => handleAction("BACKLOG", isActionItemClicked.action, puzzle.puzzleId, puzzle.userEmail, index)} className='admin-button'>{isActionItemClicked.action}</button>
                                    </div>
                                </>
                                    :
                                    <>
                                        <button className='riddle-word-text'>{puzzle.riddleWord}</button>
                                        <div style={{ textAlign: 'center', marginTop: 10, marginBottom: -10 }}>
                                            <b>Created at:</b> {new Date(puzzle.createdAt._seconds * 1000 + puzzle.createdAt._nanoseconds / 1000000).toLocaleDateString("en", {
                                                month: "long",
                                                day: "numeric",
                                                year: "numeric"
                                            })}</div>
                                        <div className='summary-content'>
                                            <Link to={`/admin/puzzle/${puzzle.puzzleId}?puzzleAuthorEmail=${puzzle.userEmail}`} target="_blank" >
                                                <button className='admin-button'>Review</button>
                                            </Link>
                                            <button onClick={() => setIsActionItemClicked({ puzzleType: "BACKLOG", index, action: "Approve" })} className='admin-button'>Approve</button>                                            <button onClick={() => setIsActionItemClicked({ puzzleType: "BACKLOG", index, action: "Reject" })} className='admin-button'>Reject</button>
                                            <button onClick={() => setIsActionItemClicked({ puzzleType: "BACKLOG", index, action: "Delete" })} className='admin-button'>Delete</button>
                                        </div>
                                    </>
                            }
                        </div>
                    }).sort((a, b) => a.key - b.key)}
                </div>
                <div>
                    <p className='dashboard-header'>Queued</p>
                    {queuedPuzzles.length == 0 ? "No queued puzzles" : null}
                    {queuedPuzzles.map((puzzle, index) => {
                        return <div
                            key={puzzle.releaseDate._seconds}
                            style={{ backgroundColor: getBackgroundColor(index) }}
                            className='puzzle-summary'>
                            {actionItemResponse.index === index && actionItemResponse.puzzleType === "QUEUED" ? <div
                                className='summary-content'>
                                <p className='are-you-sure-text'>
                                    {actionItemResponse.result === "SUCCESS" ? `${puzzle.riddleWord} was successfully ${actionItemResponse.action}${actionItemResponse.action === "Reject" ? "ed" : "d"}` :
                                        `There was an error when trying to ${actionItemResponse.action} ${puzzle.riddleWord}`}
                                </p>
                                {actionItemResponse.result === "SUCCESS" ?
                                    <FontAwesomeIcon className="checkmark-icon admin-icon" icon={faCheckCircle} /> :
                                    <FontAwesomeIcon className="xmark-icon admin-icon" icon={faXmarkCircle} />}
                            </div>
                                : isActionItemClicked.index === index && isActionItemClicked.puzzleType === "QUEUED" ? <>
                                    <p className='are-you-sure-text'>Are you sure you want to {isActionItemClicked.action} {puzzle.riddleWord}?</p>
                                    <div
                                        className='summary-content'
                                        style={{ gap: 60 }}>
                                        <button onClick={() => setIsActionItemClicked({ puzzleType: "", index: -1, action: null })} className='admin-button'>Cancel</button>
                                        <button onClick={() => handleAction("QUEUED", isActionItemClicked.action, puzzle.puzzleId, puzzle.userEmail, index)} className='admin-button'>{isActionItemClicked.action}</button>
                                    </div>
                                </>
                                    :
                                    <>
                                        <button className='riddle-word-text'>{puzzle.riddleWord}</button>
                                        <div style={{ textAlign: 'center', marginTop: 10, marginBottom: -10 }}>
                                            <b>Release Date:</b> {new Date(puzzle.releaseDate._seconds * 1000 + puzzle.releaseDate._nanoseconds / 1000000).toLocaleDateString("en", {
                                                weekday: "short",
                                                month: "long",
                                                day: "numeric",
                                                year: "numeric"
                                            })}</div>
                                        <div className='summary-content'>
                                            <Link to={`/admin/puzzle/${puzzle.puzzleId}?puzzleAuthorEmail=${puzzle.userEmail}`} target="_blank" >
                                                <button className='admin-button'>Review</button>
                                            </Link>
                                            <button onClick={() => setIsActionItemClicked({ puzzleType: "QUEUED", index, action: "Delete" })} className='admin-button'>Delete</button>
                                        </div>
                                    </>}
                        </div>
                    }).sort((a, b) => a.key - b.key)}
                </div>
            </div >

            <Footer />
        </>
    )
}

export default AdminDashboard