import React, { useContext } from 'react'
import { ContextProvider } from '../context/index';
import Loading from '../Components/Loading';

const AdminRoute = ({ children }) => {

    const { user } = useContext(ContextProvider)

    // If the user is authenticated and the user's permissions includes READ, show children
    // If the user is not authenticated, or the user is authenticated AND they don't have READ permissions, send them to the base route
    // Otherwise, show loading
    return user.isUserAuthenticated && user.permissions.includes("READ") ?
        children
        : user.isUserAuthenticated === false || (user.isUserAuthenticated && !user.permissions.includes("READ")) ?
            window.location.href = "/"
            : <Loading />
}

export default AdminRoute